import type { GetStaticProps } from "next"

import { ImageProps } from "next/legacy/image"

import { WP_MEDIA_FIELDS, WP_SEO_FIELDS } from "@/graphql-queries"
import { AcfBlocksContainer } from "@components/acf-blocks"
import { AppHead } from "@components/app-head"
import { Header } from "@components/header"
import { Layout } from "@components/layout"
import { ACFGenericBlock, WPPage } from "@models/blocks"
import { WPGlobalFields, WPSeoFields } from "@models/common"
import { getGlobalFields, getPostFields } from "@utils/acf"
import { getAppLocale } from "@utils/locale"
import { parseBlocks } from "@utils/parsers"

const GQL_404_QUERY = /* GraphQL */ `
  ${WP_MEDIA_FIELDS}
  ${WP_SEO_FIELDS}
  query GetHomeBlocks($id: ID!) {
    page(id: $id, idType: DATABASE_ID) {
      seo {
        ...WPSeoFields
      }
      featuredImage {
        node {
          ...WPMediaFields
        }
      }
      blocks {
        attributesJSON
      }
    }
  }
`

interface PageProps {
  globalFields: WPGlobalFields
  imageMap: Record<number, ImageProps>
  seo: WPSeoFields
  acfBlocks: ACFGenericBlock[]
}

export const getStaticProps: GetStaticProps<PageProps> = async context => {
  // fetch global and page fields
  const appLocale = getAppLocale(context.locale)
  const [globalFields, pageData] = await Promise.all([
    getGlobalFields(context),
    getPostFields<WPPage>(context, GQL_404_QUERY, process.env.WP_PAGEID_404),
  ])

  // parse blocks
  const { imageMap, acfBlocks } = await parseBlocks(pageData, appLocale)

  return {
    revalidate: 1 * 24 * 60 * 60, // 1 day
    props: { globalFields, imageMap, seo: pageData.page.seo, acfBlocks },
  }
}

export default function PageNotFound({ seo, globalFields, imageMap, acfBlocks }: PageProps) {
  return (
    <>
      <AppHead {...seo} />
      <Layout globalFields={globalFields} imageMap={imageMap}>
        <Header />
        <AcfBlocksContainer blocks={acfBlocks} />
      </Layout>
    </>
  )
}
